import { render, staticRenderFns } from "./addBrand.vue?vue&type=template&id=7894c310&scoped=true&"
import script from "./addBrand.vue?vue&type=script&lang=js&"
export * from "./addBrand.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7894c310",
  null
  
)

export default component.exports