<template>
  <div class="applyProductCombo-container">
    <div class="comboList">
      <div class="header2 brand_hrader">
        <van-nav-bar
          title="创建品牌"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        ></van-nav-bar>
      </div>
      <van-form validate-first class="brand_cs" style="margin-bottom: 100px">
        <van-cell-group>
          <van-cell
            title="商标分类:"
            class="van-ellipsis"
            @click="show = true"
          >
            <template #title>
                商标分类: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="query.brandClassify"
                >{{ query.brandClassify }}</span
              >
              <span style="margin-right: 15px" v-else>请选择商标分类</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="$store.state.brandType"
            @cancel="onCancel"
            @select="getBrandList"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell title="商标注册号:">
            <template #title>
                商标注册号: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <van-field
              v-model="query.brandRegisterNum"
              maxlength="30"
              placeholder="请输入商标注册号"
            >
              <van-icon name="browsing-history" slot="right-icon"  @click="recognitionNum" color='#133676' size="24px" v-if="judegIcon"/>
              <van-icon name="browsing-history"  slot="right-icon" size="24px" v-else/>
            </van-field>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="品牌名称:">
            <template #title>
                品牌名称: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <van-field
              v-model="query.brandName"
              maxlength="30"
              placeholder="识别商标注册号后自动填充"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="商标所属者名称:">
            <template #title>
                商标所属者: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <van-field
              v-model="query.iBrandName"
              maxlength="30"
              placeholder="识别商标注册号后自动填充"
            />
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="商标有效期:" @click="showPopup = true">
            <template #title>
                商标有效期: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="query.currentTrim"
                >{{ query.currentTrim }}</span
              >
              <span style="margin-right: 15px" v-else>请选择时间</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-popup v-model="showPopup" position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="商标有效期"
                @confirm="confirmTirm"
                @cancel="consoleWin"
                :min-date="minDate"
                :max-date="maxDate"
              />
            <template #title>
                商标有效期: <span style="color: red; font-size: 16px;">*</span>
            </template>
          </van-popup>
        </van-cell-group>
        <van-cell title="商标注册证:" class="titleImg_cs">
            <template #title>
                商标注册证: <span style="color: red; font-size: 16px;">*</span>
            </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :file-list="query.brandPoof"
            :on-success="
              (response) => {
                return productRowUpdateFlie(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>

        <van-cell-group>
          <van-cell title="品牌经营类型:" class="van-ellipsis" @click="show1 = true">
            <template #title>
                品牌经营类型: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="query.brandType"
                >{{ query.brandType }}</span
              >
              <span style="margin-right: 15px" v-else>请选择品牌经营类型</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show1"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="$store.state.brandLicenseScope"
            @cancel="onCancel"
            @select="getBrandType"
          />
        </van-cell-group>
        <van-cell title="授权链路说明:" v-if="query.brandType != '自有品牌'" class="titleImg_cs">
            <template #title>
                授权链路说明: <span style="color: red; font-size: 16px;">*</span>
            </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :file-list="query.brandStatePoof"
            :on-success="
              (response) => {
                return productRowUpdateFlie1(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove1(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell-group>
          <van-cell title="备注说明:">
            <div class="sk2 textarea_cs">
              <van-field
                v-model="query.remark"
                rows="2"
                autosize
                type="textarea"
                maxlength="100"
                placeholder="请输入备注说明"
                show-word-limit
              />
            </div>
          </van-cell>
        </van-cell-group>
      </van-form>
      <van-tabbar>
        <div style="width: 100%; text-align: center">
          <van-button
            style="background-color: #8585ad; color: white; width: 20%; border-radius: 6px;"
            @click="onClickLeft"
            >取消</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            @click="confirm"
            v-if="disableBtn"
            >提交</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            disabled
            v-else
            >提交</van-button
          >
        </div>
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { Empty, Notify } from "vant";
export default {
  data() {
    return {
      disableBtn: true,
      minDate: new Date(1990, 0, 1),
      maxDate: new Date(2075, 11, 31),
      currentDate: new Date(2022, 0, 1),
      currentTrim: '',
      show: false,
      show1: false,
      judegIcon: true,
      showPopup: false,
      imgType: ['image/jpg','image/jpeg','image/png','image/gif'],
      query:{
        currentTrim: '',
        brandClassify: '',
        brandRegisterNum: '',//商标注册号
        brandName: '',//品牌名称
        iBrandName: '',//
        brandType: '自有品牌',
        brandPoof: [],//商标注册证图片
        brandStatePoof: [],//授权链路说明
        remark: '',//备注说明
      },
      max: 8,
    };
  },
  methods: {
    getBrandType(item){
        this.query.brandType = item.name;
        if(item.name == '自有品牌'){
            this.query.brandStatePoof = []
        }
    },
    // 选择时间[取消]
    consoleWin(){
        this.showPopup = false;
        this.currentDate = new Date(2022, 0, 1);
    },
    // 选择时间[确定]
    confirmTirm(){
        this.showPopup = false;
        this.query.currentTrim  = this.formatDate3(this.currentDate);
    },
    // 获取商标分类
    getBrandList(item){
       this.show = false;
       this.query.brandClassify = item.name;
    },
    //识别商标注册号
    recognitionNum(){
        if(this.query.brandClassify == ''){
          Notify({ type: "danger", message: '请选择商标分类!!!' });
          return false;
        }
        if(!this.query.brandRegisterNum || this.query.brandRegisterNum.trim().length == 0){
          Notify({ type: "danger", message: '请填写商标注册号!!!' });
          return false;
        }
        this.judegIcon = false
        this.$http
        .ajax(
          "/ffkj-main/merchantBrand/recognition?brandType=" + this.query.brandClassify+"&brandNo="+this.query.brandRegisterNum,
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.success) {
            Notify({ type: "success", message: res.msg });
            this.query.brandName = res.body.brandName;
            this.query.iBrandName = res.body.brandCompany;
            this.query.currentTrim = res.body.brandExpired; 
            this.currentDate = res.body.brandExpired
          } else {
            Notify({ type: "danger", message: res.msg });
          }
          var timer = setTimeout(
            function () {
              this.judegIcon = true;
              window.clearTimeout(timer);
            }.bind(this),
            5000
          );
        });
    },
    // 上传图片格式效验
    beforeAvatarUpload(file) {
        if ( this.imgType.indexOf(file.type) == -1 ) {
            Notify({ type: "danger", message: "上传图片只能是JPG/JPEG/PNG/GIF格式" });
            return false
        }
        if ( file.size / 1024 / 1024 > 50 ) {
            Notify({ type: "danger", message: "上传图片大小不能超过50MB" });
            return false
        }
    },
    // 最多上传数量
    fnmax(el) {
      Notify({ type: "danger", message: "最多允许上传" + this.max + "张" });
    },
    // 上传商标注册证
    productRowUpdateFlie(response) {
      this.query.brandPoof.push({ url: response });
      if(this.query.brandPoof.length >= this.max) {
        Notify({ type: "warning", message: "文件上传已达上限!!!" });
      }
    },
    // 删除商标注册证
    productHandleRemove(file, fileList) {
      this.query.brandPoof = fileList;
    },

    // 上传授权链路说明
    productRowUpdateFlie1(response) {
      this.query.brandStatePoof.push({ url: response });
      if(this.query.brandStatePoof.length >= this.max) {
        Notify({ type: "warning", message: "文件上传已达上限!!!" });
      }
    },
    // 删除授权链路说明
    productHandleRemove1(file, fileList) {
      this.query.brandStatePoof = fileList;
    },
    // 取消
    onCancel() {},
    // 返回事件
    onClickLeft() {
      this.$router.push({
        path: "/brand",
        query: {
          active: 1,
        },
      });
    },
    // 提交审核
    confirm() {
        if(this.query.brandClassify == ''){
          Notify({ type: "danger", message: '请选择商标分类!!!' });
          return false;
        }
        if(!this.query.brandRegisterNum || this.query.brandRegisterNum.trim().length <= 0){
          Notify({ type: "danger", message: '商标注册号不能为空!!!' });
          return false;
        }
        if(!this.query.brandName || this.query.brandName.trim().length <= 0){
          Notify({ type: "danger", message: '品牌名称不能为空!!!' });
          return false;
        }
        if(!this.query.iBrandName || this.query.iBrandName.trim().length <= 0){
          Notify({ type: "danger", message: '商标所属者名称不能为空!!!' });
          return false;
        }
        if(this.query.currentTrim == ''){
          Notify({ type: "danger", message: '商标有效期不能为空!!!' });
          return false;
        }
        if(this.query.brandPoof.length <= 0){
          Notify({ type: "danger", message: '请上传商标注册证!!!' });
          return false;
        }
        if(this.query.brandType != '自有品牌'){
          if(this.query.brandStatePoof.length <= 0){
            Notify({ type: "danger", message: '请上传授权链路说明!!!' });
            return false;
          }
        }else{
          this.query.brandStatePoof = [];
        }
        this.disableBtn = false;
        let obj = {
          powerAttorneys: [],
          authorizationDes: [],
        }
        for(let i = 0; i < this.query.brandPoof.length; i++){
            obj.powerAttorneys.push(this.query.brandPoof[i].url);
        }

        for(let i = 0; i < this.query.brandStatePoof.length; i++){
            obj.authorizationDes.push(this.query.brandStatePoof[i].url);
        }

      this.$http
      .ajax('/ffkj-main/merchantBrand/add',
        {
          brandType: this.query.brandClassify,
          brandNo: this.query.brandRegisterNum,
          name: this.query.brandName,
          brandCompany: this.query.iBrandName,
          brandExpired: this.query.currentTrim,
          merchantId: localStorage.getItem('merchantId'),
          powerAttorneys: obj.powerAttorneys,
          authorizationDes: obj.authorizationDes,
          licenseScope: this.query.brandType,
          remarks: this.query.remark
      },'post',{}).then(res=>{
          if( res.code == 0 ){
              Notify({ type: "success", message: "创建成功!!!" });
              setTimeout(()=>{
                this.onClickLeft();
              },400)
          }
          else{
            Notify({ type: "danger", message: res.data });
            this.disableBtn = true;
          }
          var timer = setTimeout(function(){
              window.clearTimeout(timer)
          }.bind(this), 300)
      })
    },
  },
};
</script>

<style scoped>
</style>
